var errors = [];
const sofisticaRegex = new RegExp(/[^A-zÀ-ÿ\x20-\x7f]+/);
const alfanumericRegex = new RegExp(/[^-A-Za-z0-9 œ,;.!?\n\rÀ-ÿ]+|ë/, 'u');

var reg = sofisticaRegex;
const giftcardFields = ['from', 'message', 'toName', 'voucherTo', 'voucherMsg'];
var max;

/**
 * Switch to the singular if there's exactly 1 character remaining
 * @param {boolean} check the deciding value
 * @param {string} id the id of current object
 * @param {Object} characters element containing the plurar character text
 * @param {Object} character element containing character text for a singel character
 */
function swapTexts(check, id, characters, character) {
    if (check) {
        characters.show();
        character.hide();
    } else {
        characters.hide();
        character.show();
    }
}
/**
 * Make it red if there are 0 characters remaining
 * @param {boolean} check the deciding value
 * @param {Object} helperClass this is to select the element that has to change colors
 */
function markTextRed(check, helperClass) {
    if (check) {
        helperClass.removeClass('text-muted');
        helperClass.addClass('text-danger');
    } else {
        helperClass.removeClass('text-danger');
        helperClass.addClass('text-muted');
    }
}

/**
 * counts the characters in the gift message field
 */
function countCharacters() {
    var id = $(this).attr('id').split('-')[1] ? $(this).attr('id').split('-')[1] : $(this).attr('id');
    max = $(this).attr('maxlength') ? $(this).attr('maxlength') : max;
    var length = $(this).val().length;
    var counter = length ? max - length : max;
    var helper = $(this).parent('.form-group').find('div').children('[id^=gift-characters-]');
    var charactersText = $(this).parent('.form-group').find('div').children('[id^=countCharacters-]');
    var characterText = $(this).parent('.form-group').find('div').children('[id^=countCharacter-]');
    var helperClass = $(this).parent('.form-group').find('div').children('.character-count');
    helper.text(counter);
    swapTexts(counter !== 1, id, charactersText, characterText);
    markTextRed(counter === 0, helperClass);
}


/**
 * Adds error element to array
 * @param {Element} error this element contains the error span
 */
function addErrorToArray(error) {
    var found = false;
    errors.forEach(e => {
        if (e.attr('id') === error.attr('id')) {
            found = true;
        }
    });
    if (!found) {
        errors.push(error);
    }
}
/**
 * checks if any known errors are shown return true if any are shown
 * @returns {boolean} bool
 */
function checkIfErrorsAreShown() {
    let result = false;
    errors.forEach(e => {
        if (e.is(':visible')) {
            result = true;
        }
    });
    return result;
}

/**
 * Checks the value of the personal message for any unwanted charaters.
 * @param {string} text this is the latestes typed string.
 * @param {string} type this is to check if it is sender or if it is the textarea.
 * @returns {string} sends the string back.
 */
// eslint-disable-next-line no-unused-vars, require-jsdoc
function testTextArea() {
    // depending if this is giftmessage field or a giftcard field other restrisctions apply
    var id = $(this).attr('id');
    if (id.indexOf('-') > 0) {
        id = id.substring(0, id.indexOf('-'));
    }
    reg = giftcardFields.includes(id) ? alfanumericRegex : sofisticaRegex;

    var error = $(this).parent('.form-group').find('span');
    addErrorToArray(error);
    var text = $(this).val();
    error.addClass('d-none');
    if (reg.test(text) && error.hasClass('d-none')) {
        error.removeClass('d-none');
    }
    $('.submit-shipping').prop('disabled', checkIfErrorsAreShown());
    $('.save-shipment').prop('disabled', checkIfErrorsAreShown());
    $('.btn-add-giftcard').prop('disabled', checkIfErrorsAreShown());
    $('.btn-save-online-voucher').prop('disabled', checkIfErrorsAreShown());
}
/**
 * add event handlers.
 */
function init() {
    $('[id^=giftMessage-]').on('input', testTextArea);
    $('[id^=giftSender-]').on('input', testTextArea);
    $('#from').on('input', testTextArea);
    $('#toName').on('input', testTextArea);
    $('[id^=toName-]').on('input', testTextArea);
    $('#message').on('input', testTextArea);
    $('[id^=voucherTo-]').on('input', testTextArea);
    $('[id^=voucherMsg-]').on('input', testTextArea);
    $('[id^=isGift-]').on('change', function () {
        var id = $(this).attr('id').split('isGift-')[1];
        if ($(this).prop('checked') && $('#gift-message-' + id).hasClass('d-none')) {
            $('#gift-message-' + id).removeClass('d-none');
        } else {
            $('#gift-message-' + id).addClass('d-none');
        }
    });
    $('[id^=giftMessage-]').each(countCharacters);
    $('[id^=giftMessage-]').keyup(countCharacters);
    $('#message').each(countCharacters);
    $('#message').keyup(countCharacters);
}

module.exports = {
    init: init
};
